<template>
  <form class="checkout-form" @submit.prevent="checkForm">
    <div class="row">
      <div class="col-lg-8">
        <h4 class="widget-title">Email address</h4>
        <div class="row mb-5 shipping-details">
          <small class="mb-3">(This is your account email)</small>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" class="form-control" id="email" name="email" v-model="email">
            </div>
          </div>
        </div>
        <h4 class="widget-title">Shipping address</h4>
        <div class="alert alert-danger mt-3" v-if="errors.length > 0" v-for="error in errors">
          {{error}}
        </div>
        <div class="row mb-5 shipping-details">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" name="name" v-model="shipping.name">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="surname">Surname</label>
              <input type="text" class="form-control" id="surname" name="surname" v-model="shipping.surname">
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="street">Street</label>
              <input type="text" class="form-control" id="street" name="address" v-model="shipping.address">
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="street_number">Str. number</label>
              <input type="text" class="form-control" id="street_number" name="numciv" v-model="shipping.numciv">
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label for="user_post_code">Zip Code</label>
              <input type="text" class="form-control" id="user_post_code" name="zipcode" v-model="shipping.cap" maxlength="7" v-on:change="getShippingPrice">
            </div>
          </div>
          <div class="col-lg-8">
            <div class="form-group" >
              <label for="user_city">City</label>
              <input type="text" class="form-control" id="user_city" name="city" v-model="shipping.city">
            </div>
          </div>
          <div class="col-lg-6" v-if="shipping.country === 'Italy' || shipping.country === 'Canada'">
            <div class="form-group">
              <label for="prov">Province</label>
              <input type="text" class="form-control" id="prov" name="prov" maxlength="2" v-model="shipping.prov">
            </div>
          </div>
          <div class="col-lg-6" v-if="shipping.country === 'Brazil'">
            <div class="form-group" >
              <label for="cpf">C.P.F.</label>
              <input type="text" class="form-control" id="cpf" name="cpf" maxlength="11" v-model="shipping.cpf">
            </div>
          </div>
          <div class="col-lg-6" v-if="shipping.country === 'Qatar'">
            <div class="form-group" >
              <label for="cpf">ID number</label>
              <input type="text" class="form-control" id="IDnumber" name="IDnumber" maxlength="11" v-model="shipping.IDnumber">
            </div>
          </div>
          <div class="col-lg-6" v-if="shipping.country === 'Romania'">
            <div class="form-group" >
              <label for="cpf">County</label>
              <input type="text" class="form-control" id="county" name="county" v-model="shipping.county">
            </div>
          </div>
          <div class="col-lg-6" v-if="shipping.country === 'Turkey'">
            <div class="form-group" >
              <label for="cpf">Turkish ID</label>
              <input type="text" class="form-control" id="turkishID" name="turkishID" v-model="shipping.turkishID">
            </div>
          </div>
          <div class="col-lg-6" v-if="shipping.country === 'United States'">
            <div class="form-group" >
              <label for="cpf">State</label>
              <input type="text" class="form-control" id="stateUS" name="stateUS" v-model="shipping.stateUS">
            </div>
          </div>
          <div :class="(shipping.country === 'Italy' || shipping.country === 'Canada' || shipping.country === 'Brazil'
          || shipping.country === 'Qatar' || shipping.country === 'Romania' || shipping.country === 'Turkey' || shipping.country === 'United States') ? 'col-lg-6' : 'col-lg-12'">
            <div class="form-group">
              <label for="shipping_country">Country</label>
              <select class="form-control" id="shipping_country" v-model="shipping.country" v-on:change="getShippingPrice">
                <option v-bind:value="country" v-for="country in this.countries">{{country}}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="user_phone">Phone</label>
              <input type="text" class="form-control" id="user_phone" v-model="shipping.phone">
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-check">
              <input class="form-check-input checkbox-primary" type="checkbox" id="sameaddress" v-model="sameaddress">
              <label class="form-check-label" for="sameaddress">
                Same address for billing
              </label>
            </div>
          </div>
        </div>
        <h4 class="widget-title" v-if="!sameaddress">Billing address</h4>
        <div class="row mb-5 billing-details" v-if="!sameaddress">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Company</label>
              <input type="text" class="form-control" name="company" v-model="billing.company">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="full_name">VAT Number</label>
              <input type="text" class="form-control" name="vatnumber" v-model="billing.vatnumber">
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label for="full_name">SDI Code</label>
              <input type="text" class="form-control" name="sdicode" v-model="billing.sdicode">
            </div>
          </div>
          <div class="col-lg-8">
            <div class="form-group">
              <label for="full_name">PEC</label>
              <input type="text" class="form-control" name="pec" v-model="billing.pec">
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-7">
            <div class="form-group">
              <label for="user_address">Street</label>
              <input type="text" class="form-control" v-model="billing.address">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-5">
            <div class="form-group">
              <label for="full_name">Number</label>
              <input type="text" class="form-control" v-model="billing.numciv">
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label for="user_post_code">Zip Code</label>
              <input type="text" class="form-control" maxlength="7" v-model="billing.cap">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="user_city">City</label>
              <input type="text" class="form-control" v-model="billing.city">
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label for="user_city">State/Prov</label>
              <input type="text" class="form-control" maxlength="2" v-model="billing.prov">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="user_country">Phone</label>
              <input type="text" class="form-control" v-model="billing.phone">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="billing_country">Country</label>
              <select class="form-control" id="billing_country" v-model="billing.country">
                <option v-bind:value="country" v-for="country in this.countries">{{country}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <h4 class="widget-title">Order notes</h4>
          <div class="col-lg-12">
            <div class="form-group">
              <textarea class="form-control" v-model="notes"></textarea>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <h4 class="widget-title">Payment Methods</h4>
          <div class="d-flex flex-row pb-3">
            <div class="d-flex align-items-center pe-2">
              <input class="form-check-input" type="radio" name="paypal" id="paypal" value="paypal" checked v-model="paymentType"/>
            </div>
            <div class="rounded border d-flex w-100 p-3 align-items-center">
              <p class="mb-0">
                <i class="fab fa-cc-visa fa-lg text-primary pe-2"></i>
                PayPal
              </p>
            </div>
          </div>
          <div class="d-flex flex-row pb-3">
            <div class="d-flex align-items-center pe-2">
              <input class="form-check-input" type="radio" name="braintree" id="braintree" value="braintree" v-model="paymentType"/>
            </div>
            <div class="rounded border d-flex w-100 p-3 align-items-center">
              <p class="mb-0">
                <i class="fab fa-cc-visa fa-lg text-primary pe-2"></i>
                Credit card
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="product-checkout-details">
          <h4 class="widget-title">Order Summary</h4>
          <div class="discount-code" style="border-top: 0px">
            <p>Gift card/Promo code</p>
            <div class="input-group">
              <input type="text" class="form-control" v-model="discountCode">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-brand btn-sm" type="button" v-on:click="applyDiscountCode" :class="applyDiscount ? 'disabled' : ''">Apply</button>
              </div>
            </div>
            <br>
          </div>
          <ul class="summary-prices">
            <li>
              <span>Subtotal:</span>
              <span class="price">{{formatPrice(this.subtotal)}}</span>
            </li>
            <li>
              <span>Discount:</span>
              <span>{{formatPrice(this.discount)}}</span>
            </li>
            <li v-if="this.gifts > 0">
              <span>Gifts:</span>
              <span>{{formatPrice(this.gifts)}}</span>
            </li>
            <li>
              <span>Shipping:</span>
              <span>{{formatPrice(this.totalshipping)}}</span>
            </li>
          </ul>
          <div class="summary-total">
            <span>TOTAL</span>
            <span>{{formatPrice(this.total)}}</span>
          </div>
          <button type="submit" class="btn btn-brand btn-sm mt-20">Place Order</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
//commento
import router from "@/router";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CheckoutForm",
  data() {
    return {
      idcart : null,
      paymentType : "paypal",
      sameaddress : true,
      user: null,
      subtotal : 0,
      totalshipping : 0,
      discount : 0,
      gifts : 0,
      total : 0,
      email : null,
      shipping : {},//indirizzo di spedizione
      billing : {},//indirizzo di fatturazione
      discountCode : null,//codice sconto
      applyDiscount: false, //variabile che disattiva pulsante apply
      notes : null,
      errors : [],
      countries : [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Antigua",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Canary Islands",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czech Republic",
        "Democratic Rep. of Congo",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Dubai",
        "East Timor",
        "Ecuador",
        "Eel",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Holland",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kosovo",
        "Kuwait",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mariana Islands",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Nevis",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "North Korea",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Principality of Monaco",
        "Puerto Rico",
        "Qatar",
        "Republic of Congo",
        "Romania",
        "Russia",
        "Rwanda",
        "Réunion",
        "Saint Eustatius",
        "Saint Kitts",
        "Saint Vincent",
        "Saint-Barthélemy",
        "Saint-Martin",
        "Samoa",
        "Santa Lucia",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Solomon Islands",
        "Somalia",
        "Somaliland",
        "South Africa",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "St. Helena",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "São Tomé and Príncipe",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkmenistan",
        "Turks and Caicos",
        "Tuvalu",
        "Turkey",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "US Virgin Islands",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ]
    }
  },
  beforeMount(){
    this.updateDataUser();
    this.getCart();
  },
  methods: {
    async updateDataUser() {//attenzione che va a modificare il localstorage che serve anche al menu cms
      this.$store.commit('showSpinner');
      let token = localStorage.getItem("token");
      await axios.get("user", {
        headers: { Authorization: `Bearer ${token}` }
      }).then((res) => {
        this.$store.commit('hideSpinner');
        let user = res.data;
        localStorage.setItem("user", JSON.stringify(user));
        this.email = user.email;
        this.shipping.name = user.name;
        this.shipping.surname = user.surname;
        this.shipping.address = user.address;
        this.shipping.numciv = user.numciv;
        this.shipping.cap = user.cap;
        this.shipping.city = user.city;
        this.shipping.prov = user.prov;
        this.shipping.phone = user.phone;
        this.shipping.country = user.country;
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        if (error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Technical problems on input data. Check your checkout fields.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#e20036',
          });
          console.log(error);
        } else {
          console.log(error);
        }
      });
    },
    checkForm(){
      this.errors = [];
      if (!this.email || !this.shipping.name || !this.shipping.surname
          || !this.shipping.address || !this.shipping.cap || !this.shipping.city || !this.shipping.phone || !this.shipping.country) {
        this.errors.push('There are required fields. Please check the form below.');
      }
      if((this.shipping.country === "Italy" && !this.shipping.prov) ||
          (this.shipping.country === "Canada" && !this.shipping.prov)){
        this.errors.push('Province is required.');
      }
      if(!this.shipping.numciv){
        this.errors.push('Street number is required. Please enter snc if not exist.');
      }
      if((this.shipping.country === 'Italy' || this.shipping.country === 'Canada') && this.shipping.prov && this.shipping.prov.length > 2){//per la spagna e altri paesi non ci sono province
        this.errors.push('The field prov must have 2 characters.');
      }
      if (this.errors.length === 0) {
        this.placeOrder();
      }
    },
    formatPrice(value) {
      return Number(value/100)
          .toFixed(2)
          .replace(".", ",");
    },
    async getCart() {
      this.$store.commit('showSpinner');
      let idcart = localStorage.getItem("cartIdBKL");
      let token = localStorage.getItem("token");
      await axios.get("cart?idcart=" + idcart, {
        headers: { Authorization: `Bearer ${token}` }
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        if(!resp.data.cart.lineItems.length) {
          router.push("/");
        }else {
          this.idcart = resp.data.cart.id;
          this.total = resp.data.cart.totals.total;
          this.totalshipping = resp.data.cart.totals.shipping;
          this.gifts = resp.data.cart.totals.gifts;
          this.subtotal = resp.data.cart.totals.subtotal;
          this.discount = resp.data.cart.totals.discount;
          if (this.shipping.cap) {
            //ottengo costo spedizioni
            this.getShippingPrice();
          }
        }
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        console.log(error);
      });
    },
    async getShippingPrice() {
      let token = localStorage.getItem("token");
      await axios.post("getshippingprice", {
        total : this.total,
        address : this.shipping,
        idcart : this.idcart
      },{
        headers: { Authorization: `Bearer ${token}` }
      }).then((resp) => {
        this.total = (this.total - this.totalshipping) + resp.data;
        this.totalshipping = resp.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    async applyDiscountCode(){
      if(!this.applyDiscount) {
        let token = localStorage.getItem("token");
        await axios.post("checkcoupon", {
          code: this.discountCode,
          totalcart: JSON.stringify(this.subtotal)
        }, {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          this.discount = this.subtotal - resp.data.newtotal * 100;
          this.subtotal = resp.data.newtotal > 0 ? resp.data.newtotal * 100 : 0;
          this.total = this.subtotal + this.gifts + this.totalshipping;
          this.applyDiscount = true;
        }).catch((error) => {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Empty discount code",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#e20036',
          });
          console.log(error);
        });
      }
    },
    async placeOrder(){
      this.$store.commit('showSpinner');
      let token = localStorage.getItem("token");
      await axios.post("checkout", {
        email : this.email,
        paymentType : this.paymentType,
        shipping : this.shipping,
        billing : this.billing,
        idcart : this.idcart,
        notes : this.notes,
        discountCode : this.discountCode
      },{
        headers: { Authorization: `Bearer ${token}` }
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        router.push("checkout/" + resp.data.idorder);
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        if(error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Technical problems on input data. Check your checkout fields.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#e20036',
          });
          console.log(error);
        }else{
          console.log(error);
        }
      });
    }
  }
}
</script>

<style scoped>

</style>